.preloader {
  position: fixed;
  height: 100vh;
  left: 0;
  top: 0;
  width: 100%;
  background: #000;
  z-index: 1010;

  &.done {
    display: none;
    // animation-name: doAnimStay;
  	// animation-duration: 5s;
  	// animation-fill-mode: forwards;/* Makes it stay after animation */
  }
}

@property --num {
  syntax: "<integer>";
  initial-value: 100;
  inherits: false;
}

.loader {
  animation: counter 6s 1 normal ease-in;
  counter-reset: num var(--num);
  font-size: 5rem;
  font-weight: 700;
  padding: 2rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: $black;
  -webkit-text-stroke: 1px $white;
  opacity: 0.225;

  &:before {
    content: counter(num);
  }
  &:after {
    content: '%';
  }
}

@keyframes counter {
  0% {
    --num: 0;
  }
  99% {
    --num: 99;
  }

}

@keyframes doAnimStay {
  0%   {transform: translateY(0px); opacity: 1; }
  50% {transform: translateY(0px); opacity: 0;;}
  100% {transform: translateY(-9999px); opacity: 0;}
}

// Import font from google
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');

@font-face {
font-family: 'GorditaRegular';
src: url('../fonts/GorditaRegular.eot');
src: url('../fonts/GorditaRegular.svg') format('embedded-opentype'),
url('../fonts/GorditaRegular.woff2') format('woff2'),
url('../fonts/GorditaRegular.woff') format('woff'),
url('../fonts/GorditaRegular.ttf') format('truetype');
}
@font-face {
font-family: 'Gordita-Medium';
src: url('../fonts/Gordita-Medium.eot');
src: url('../fonts/Gordita-Medium.svg') format('embedded-opentype'),
url('../fonts/Gordita-Medium.woff2') format('woff2'),
url('../fonts/Gordita-Medium.woff') format('woff'),
url('../fonts/Gordita-Medium.ttf') format('truetype');
}
@font-face {
font-family: 'Gordita-Bold';
src: url('../fonts/Gordita-Bold.eot');
src: url('../fonts/Gordita-Bold.svg') format('embedded-opentype'),
url('../fonts/Gordita-Bold.woff2') format('woff2'),
url('../fonts/Gordita-Bold.woff') format('woff'),
url('../fonts/Gordita-Bold.ttf') format('truetype');
}

//Heading h1
h1,
.h1 {
  font-size: $h1-font-size;
  font-family: $theme-font-family-heading-bold;
  font-weight: 900;
}

h1.huge,
.h1.huge {
  font-size: $h1-font-size * fontSize(130); // 200
}

h1.large,
.h1.large {
  font-size: $h1-font-size * fontSize(120); // 175
}

h1.medium,
.h1.medium {
  font-size: $h1-font-size * fontSize(110); //150
}

h1.small,
.h1.small {
  font-size: $h1-font-size * fontSize(100); //125
}

h1 {
  &.normal {
    font-family: $theme-font-family-heading-regular;
  }
}

// Heading h2
h2, .h2 {
  font-family: $theme-font-family-heading-bold;
  font-size: $h2-font-size;
  //font-weight: 100;
}

// Heading h3
h3, .h3 {
  font-size: $h3-font-size;
  font-family: $theme-font-family-heading-bold;
  //font-weight: 100;
}

// Heading h4
h4, .h4 {
  font-size: $h4-font-size;
  font-family: $theme-font-family-heading-medium;
  color: $black;
  //font-weight: 100;
}

// Heading h5
h5, .h5 {
  font-size: $h5-font-size;
  font-family: $theme-font-family-heading-regular;
  color: $black;
}

// Heading h6
h6, .h6 {
  font-size: $h6-font-size * fontSize(75);;
  font-family: $theme-font-family-heading-regular;
  color: $black;
  text-transform: uppercase;
  letter-spacing: 2px;
  &:before {
    content: "/"
  }
}

.hairline {
  font-family: $theme-font-family-primary;
  font-weight: 300;
}

//
// Paragraph

p {
  line-height: 34px;
  margin: 0 0 2rem 0;
}

@include media-breakpoint-up(sm) {
  h1.huge,
  .h1.huge {
    font-size: $h1-font-size * fontSize(135);
  }

  h1.large,
  .h1.large {
    font-size: $h1-font-size * fontSize(125);
  }

  h1.medium,
  .h1.medium {
    font-size: $h1-font-size * fontSize(112.5);
  }

  h1.small,
  .h1.small {
    font-size: $h1-font-size * fontSize(100);
  }
}

@include media-breakpoint-up(md) {
  h1.huge,
  .h1.huge {
    font-size: $h1-font-size * fontSize(155);
  }

  h1.large,
  .h1.large {
    font-size: $h1-font-size * fontSize(135);
  }

  h1.medium,
  .h1.medium {
    font-size: $h1-font-size * fontSize(125);
  }

  h1.small,
  .h1.small {
    font-size: $h1-font-size * fontSize(100);
  }
}

@include media-breakpoint-up(lg) {
  h1.huge,
  .h1.huge {
    font-size: $h1-font-size * fontSize(200);
  }

  h1.large,
  .h1.large {
    font-size: $h1-font-size * fontSize(175);
  }

  h1.medium,
  .h1.medium {
    font-size: $h1-font-size * fontSize(150);
  }

  h1.small,
  .h1.small {
    font-size: $h1-font-size * fontSize(125);
  }
}

canvas{
  filter: blur(1px);
  position: absolute;
  top: 0;
}

.header-slide {
  //padding-top: 12rem;
  position: relative;
  p {
    padding-right: 12rem;
    padding-left: .75rem;
    line-height: 28px;
    font-size: $font-size-base * fontSize(125);
  }

  h1.huge {
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    background-image: linear-gradient(45deg, #ffffff 16.66666%, #ffffff 33.33333%, #aee8fa 50%, #68bde7 66.66666%, #6bd490 83.33333%, #a2f6cf);
    background-size: 300% 100%;
    animation: gradient-anim 8s linear infinite;
    animation-direction: alternate;
    animation-timing-function: ease;
    animation-fill-mode: forwards;
    font-size: $h1-font-size * fontSize(175); // Defined in Sass function
    line-height: 120px;
  }
}

@keyframes gradient-anim {
  0% {
    background-position: 0%
  }
  100% {background-position: 100%}
}

//
// Section
.section-ak {
  position: relative;
  &.dark {
    background-color: $mk-section-bg;
    h4, p, h6, h1 {
      color: #fff;
    }
  }
  &.bright {
    background-color: $mk-section-bg-blue;
  }

  &.pv {
    padding: 8rem 0;
  }
  &.pt {
    padding-top: 8rem;
  }
  &.pb {
    padding-bottom: 8rem;
  }
}

//

.section-vh {
  height: 100vh;
  background-color: $mk-gray-300;
}

.black {
  background-color: $black;
  color: #fff;
}

.display-block {
  background-image: url('../images/display-1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  height: 85vh;

  h1 {
    color: #fff;
    font-family: $theme-font-family-heading-regular;
    //font-size: 4rem;
  }
}

//

footer {
  width: 100vw;
  height: 100%;
  max-height: 900px;
  overflow: hidden;
  position: relative;
  background-color: #000;
}

footer .footer__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  opacity: .8;
}

.background-image {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

footer .overlay2 {
  position: absolute;
  height: 40%;
  left: 0;
  top: 0;
  width: 100%;
  background: #000;
  background: linear-gradient(180deg, #000 0%, rgba(0,0,0,0) 100%);
  pointer-events: none;
  z-index: 2;
}

.ak-logo-footer {
  width: 60px;
  opacity: .5;
}

.footer-area {
  padding-top: 15rem;
  padding-bottom: 8rem;
  color: #fff;

  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}

.nav-head {
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 3px;
  text-transform: uppercase;
  padding: 1rem 0;
  display: block;
}

.nav-link {
  color: #fff;
  padding-left: 0;
  &:hover {
    color: #fff;
    //text-decoration: underline;
  }
}


// Sliding Text

.sliding-text-wrapper {
  position: relative;
  //display: flex;
  //justify-content: center;
  padding-bottom: 350px;
  padding-top: 170px;
}

.sliding-text {
  // position: absolute;
  // left: 0;
  // top: 0px;
  white-space: nowrap;
  //-webkit-animation: sliding-text 4s linear infinite;
  //animation: sliding-text 4s linear infinite;
  //transition-property: color;
  font-size: 8rem;
  font-family: $theme-font-family-heading-bold;
  line-height: .91667em;
  letter-spacing: -.03em;
  color: $mk-gray-300;
}

//

// @-webkit-keyframes sliding-text {
//   0% {
//     transform: translateX(-25%);
//   }
//   to {
//     transform: translateX(-45%);
//   }
// }
// @keyframes sliding-text {
//   0% {
//     transform: translateX(-25%);
//   }
//   to {
//     transform: translateX(-45%);
//   }
// }


.design-process {
  width: 50%;
  position: absolute;
  top: 465px;
  left: 15%;
  z-index: 10;
  h1 {
    font-size: 4rem;
    line-height: 120px;
    text-transform: uppercase;
    color: rgba(255,255,255,0);
    //text-shadow: 0px 15px 20px rgba($mk-silver, 0.3);
    -webkit-text-stroke: 1px rgba(0,0,0,0.3);
  }
}

.project {
  position: relative;
  padding: 0 40px 30px;
  background: $mk-purple;
  border-radius: 0px 60px 12px 12px;
  width: 100%;
  height: 100%;
  transition: all .3s ease-in;

  .project-details {

  }

  .project-media {
    margin-top: -5%;
    width: 100%;
    border-radius: 12px;
    overflow: hidden;
    -webkit-transform: scale(1);
  	transform: scale(1);
  	-webkit-transition: .3s ease-in-out;
  	transition: .3s ease-in-out;
  }

  h3 {
    margin-top: 12px;
    color: $white;
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  	-webkit-transition: .3s ease-in-out;
  	transition: .3s ease-in-out;
  }

  &:hover {
    .project-media {
      -webkit-transform: scale(1.05);
	    transform: scale(1.05);
    }
    h3 {

      -webkit-transform: translateY(10px);
	    transform: translateY(10px);

    }
  }

  .watch {
    position: absolute;
    left: -8%;
    top: 12%;
    animation: watch-anim 1200ms linear infinite;
    animation-direction: alternate;
    //animation-timing-function: ease;
    //animation-fill-mode: forwards;
  }

  @keyframes watch-anim {
    0% { top: 12%; }
    100% { top: 7%; }
  }

  .shoes {
    position: absolute;
    right: -14%;
    bottom: -5%;
    animation: shoes-anim 1600ms linear infinite;
    animation-direction: alternate;
  }

  @keyframes shoes-anim {
    0% { bottom: -5%; }
    100% { bottom: -11%; }
  }

  .shopify {
    position: absolute;
    top: -20%;
    left: 8%;
  }

  .woocommerce {
    position: absolute;
    right: -10%;
    top: 20%;
  }

  .squarespace {
    position: absolute;
    left: 30%;
    bottom: -16%;
  }

}

// Project mobile only

.project-mobile {
  margin: 5rem 0;
  img {
    width: 110%;
  }
}

//end project mobile only

.stack {
  .stack-image {
    img {
      width: 100%;
    }
  }
  .stack-text {
    margin-top: 1.5rem;
  }
}

//
// About page

.image-block {
  //height: 100vh;
  margin: 4rem 0 0;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    opacity: 0;
    transform: scale(1.2);
    transition: opacity 1.2s cubic-bezier(.215,.61,.355,1),transform 0.8s cubic-bezier(.215,.61,.355,1);
  }
}

.is-inview  {
  &.image-block {
    img {
      opacity: 1;
      transform: scale(1);
      transition-delay: .3s;
    }
  }
}

.quality-ux-design {
  background-image: url("../images/quality-ux-design.jpg");
}

.team {
  position: relative;
  text-align: center;
  .team-media {
    position: relative;
    z-index: 2;
    overflow: hidden;
    img {
      width: 100%;
      transform: scale(1);
      transition: transform 0.2s ease-in
    }
  }
  .team-detail {

    h2 {
      overflow: hidden;
      span {
        display: block;
        transform: translateY(100%);
        transition: transform 0.15s ease-in-out, opacity 0.2s ease-in-out;
        opacity: 0;
      }
    }
    span {
      display: block;
      overflow: hidden;
      span {
        transform: translateY(100%);
        transition: transform 0.15s ease-in-out 0.17s, opacity 0.2s ease-in-out 0.17s;
        display: block;
        opacity: 0;
      }
    }
  }
  &:hover {
    .team-media {
      img {
        transform: scale(1.05);
      }
    }
    .team-detail {
      h2 {
        span {
          transform: translateY(0);
          opacity: 1;
        }
      }
      span {
        span {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }
}

.faq {
  flex-wrap: wrap;
  border-bottom: 1px solid rgba($black, 0.15);
  align-items: flex-start;
  padding: 3rem 0;

  &:nth-of-type(1) {
    border-top: 1px solid rgba($black, 0.15);
  }

  .faq-question,
  .faq-answer {
    width: 50%;

    p {
      margin: 0;
    }
  }

  .faq-question {
    padding-right: 4rem;
  }
}

.faq-anim {
  align-items: center;
  display: block;
  position: relative;
  &:after {
    content: "😀";
    padding-left: 10px;
    animation-name: faqAnim;
    animation-duration: 10s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-play-state: running;
    animation-direction: alternate;
  }
  &:hover:after {
    animation-play-state: paused;
  }
}

@keyframes faqAnim {
  0% {
    content: "😀";
  }
  10% {
    content: "🐶";
  }
  15% {
    content: "💂‍♀️";
  }
  20% {
    content: "🐹";
  }
  25% {
    content: "👹";
  }
  30% {
    content: "🐋";
  }
  35% {
    content: "👍";
  }
  40% {
    content: "🦊";
  }
  45% {
    content: "🐳";
  }
  50% {
    content: "🍔";
  }
  55% {
    content: "🏉";
  }
  60% {
    content: "🦁";
  }
  65% {
    content: "🏡";
  }
  70% {
    content: "📺";
  }
  75% {
    content: "🐲";
  }
  80% {
    content: "😎";
  }
  85% {
    content: "🐢";
  }
  90% {
    content: "🎅";
  }
  95% {
    content: "🧠";
  }
  100% {
    content: "🐡";
  }
}

.contact-anim {
  align-items: center;
  display: block;
  position: relative;
  &:after {
    transform: rotate(180deg);
    content: "✋";
    padding-left: 10px;
    animation-name: contactAnim;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-play-state: running;
    animation-direction: alternate;
  }
  &:hover:after {
    animation-play-state: paused;
  }
}

@keyframes contactAnim {
  12% {
    content: "☝️";
  }
  24% {
    content: "✌️";
  }
  36% {
    content: "🤞";
  }
  48% {
    content: "🤘";
  }
  60% {
    content: "🤟";
  }
  72% {
    content: "🖖";
  }
  84% {
    content: "🖐";
  }
  100% {
    content: "✋";
  }
}

//
// Forms

.honeypot-field {
  display: none;
}

.thankyou_message {
  height: 40vh;
  .nav-link {
    color: $black;
    &:hover {
      color: $black;
    }
  }
}

.contact-form {
  .nav-link {
    color: $black;
    &:hover {
      color: $black;
    }
  }
}

.form-floating>.form-control {
  padding: 1rem 1.25rem;
}

.form-floating>label {
  padding: 1rem 1.25rem;
}

//
// 404 error page

.not-found {
  margin-top: 10rem;
}

//
//// Responsive layout

@include media-breakpoint-down(sm) {
  .header-slide {
    //padding-top: 10rem;
    p {
      padding-right: 0;
      margin-top: inherit;
    }
    h1.huge {
      font-size: $h1-font-size * fontSize(175);
      line-height: 90px;
    }
  }

  .menu__item {
    justify-content: flex-start;
  }

  .project {
    width: 80%;
  }

  footer #inner_footer {
    margin-left: 6%;
    transform: translateY(-270%);
  }
  footer #inner_footer a.main_link {
    font: 100 normal 30px/30px $theme-font-family-base;
  }

  .background-image {
    display: none;
  }

  .footer-area {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .faq {
    display: block;

    .faq-question,
    .faq-answer {
      width: 100%;
    }

    .faq-question {
      padding-right: 0;
    }
  }

  .team {
    .team-detail {

      h2 {
        span {
          transform: translateY(0);
          opacity: 1;
        }
      }
      span {
        span {
          transform: translateY(0);
          opacity: 1;
        }
      }
    }
  }

  .image-block {
    img {
      opacity: 1;
    }
  }

  .section-ak {
    &.pv {
      padding: 4rem 0;
    }
    &.pt {
      padding-top: 4rem;
    }
    &.pb {
      padding-bottom: 4rem;
    }
  }

}

@include media-breakpoint-up(md) {
  .header-slide {
    p {
      padding-right: 6rem;
      margin-top: inherit;
    }
    h1.huge {
      font-size: $h1-font-size * fontSize(225);
      line-height: 100px;
    }
  }

  .menu__item {
    justify-content: flex-start;
  }

  .project {
    width: 90%;
    margin-top: 7rem;
  }

  .footer-area {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }

  .faq {
    display: block;

    .faq-question,
    .faq-answer {
      width: 100%;
    }

    .faq-question {
      padding-right: 0;
    }
  }

  .section-ak {
    &.pv {
      padding: 6rem 0;
    }
    &.pt {
      padding-top: 6rem;
    }
    &.pb {
      padding-bottom: 6rem;
    }
  }

}

@include media-breakpoint-up(lg) {
  .header-slide {
    p {
      padding-right: 12rem;
      margin-top: -2.5rem;
    }
    h1.huge {
      font-size: $h1-font-size * fontSize(300);
      line-height: 120px;
    }
  }

  .project {
    margin-top: 12rem;
  }

  .footer-area {
    padding-top: 15rem;
    padding-bottom: 8rem;
  }

  .faq {
    display: flex;

    .faq-question,
    .faq-answer {
      width: 50%;
    }

    .faq-question {
      padding-right: 4rem;
    }
  }

}

@include media-breakpoint-up(xl) {

  .project {
    width: 100%;
    margin-top: 0rem;
  }

}

@include media-breakpoint-up(xxl) {

  .project {
    width: 100%;
    margin-top: 0rem;
  }
}

/* ------  Menu Button ------- */

.menu-toggle {
  position: absolute;
  width: 72px;
  height: 72px;
  top: -2px;
  right: 30px;
}

.menu-link {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1040;
}

.menu-icon {
  position: absolute;
  width: 20px;
  height: 14px;
  margin: auto;
  left: 0;
  top: 0;
  right: 0;
  bottom: 1px;
}

/* ------  Menu Line ------- */

.menu-line {
  background-color: $white;
  height: 2px;
  width: 100%;
  border-radius: 0px;
  position: absolute;
  left: 0;
  opacity: 1;
  transition: all 200ms ease;
  z-index: 1030;
}

.menu-line-1 {
  top: 4px;
  margin: auto;
}
.menu-line-2 {
  top: 14px;
}

.menu-link:hover .menu-line-2 {
  background-color: $black;
  opacity: 1;
  transform: translateY(-10px);
}

.menu-link:hover .menu-line-1 {
  background-color: $black;
  opacity: 1;
  transform: translateY(10px);
}

.menu-link:hover .menu-pulse, {
  border: none;
}

.menu-toggle.menu-open .menu-line-1 {
  transform: translateY(8px) translateY(-50%) rotate(-225deg);
}
.menu-toggle.menu-open .menu-line-2 {
  transform: translateY(-4px) translateY(50%) rotate(225deg);
}

.menu-toggle.menu-open .menu-link:hover .menu-line-1,
.menu-toggle.menu-open .menu-link:hover .menu-line-2 {
  background-color: $white;
  opacity: 0.3;
}

/* ------ Menu Circle ------- */

.menu-circle {
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0);
  border: 2px solid rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  left: -1px;
  bottom: -2px;
  position: absolute;
  border-radius: 50%;
  z-index: 800;
  opacity: 0.2;
  transform: scale(1);
  transition: all 200ms cubic-bezier(0.645, 0.045, 0.355, 1);
  -webkit-box-shadow: 0px 0px 32px 0px rgba(22,24,58,0.05);
  -moz-box-shadow: 0px 0px 32px 0px rgba(22,24,58,0.05);
  box-shadow: 0px 0px 32px 0px rgba(22,24,58,0.05);
}

.menu-pulse {
  border: 2px solid white;
  border-radius: 50%;
  position: absolute;
  display: block;
  width: inherit;
  height: inherit;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  box-sizing: content-box;
  opacity: 0;

  &.is-learned {
    visibility: hidden;
  }
}

.first-pulse {
  animation: pulse-border 2000ms ease-out infinite;
}
.second-pulse {
  animation: pulse-border 2000ms 400ms ease-out infinite;
}

@keyframes pulse-border {
  0% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);
    opacity: 0;
  }
  100% {
    transform: translateX(-50%) translateY(-50%) translateZ(0) scale(2);
    opacity: 0;
    border-width: 1px;
  }
}

.menu-toggle:hover .menu-circle {
  background-color: rgba($white, 1);
  border: 2px solid rgba($black, 1);
  opacity: 1;
  transform: scale(0.8);
  transition: all 400ms cubic-bezier(0.19, 1, 0.22, 1);

  -webkit-box-shadow: 0px 0px 32px 0px rgba(22,24,58,0.1);
  -moz-box-shadow: 0px 0px 32px 0px rgba(22,24,58,0.1);
  box-shadow: 0px 0px 32px 0px rgba(22,24,58,0.1);
}

.menu-toggle.menu-open .menu-circle {
  transform: scale(50);
  transition: all 2000ms cubic-bezier(0.19, 1, 0.22, 1), opacity 800ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
}

/* ------ Menu Overlay ------- */
.menu-overlay {
  background-color: $black;
  color: $white;
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0px;
  top: 0;
  //text-align: center;
  transition: opacity 0.2s ease-in-out;
  z-index: 1020;
  opacity: 0;
  visibility: hidden;
  overflow-y: hidden;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;

  &.menu-open {
    opacity: 1;
    visibility: visible;
  }

  h6,
  h5,
  a {
    color: $white;
  }
}

.overlay-content{
  height: 100vh;
  padding: 4rem 0 3rem 0;
}

// Main Menu Items

.main-menu {
  .nav-link {
    font-size: 3rem;
    font-weight: 100;
    padding: 0;
    color: $white;
    text-transform: uppercase;
    margin: 0.75rem 0;
    position: relative;

    &:hover {
      animation: mainMenuEffect 0.3s ease-in-out 1;
      font-weight: 500;
      &:before {
        content: "/";
        position: absolute;
        left: -30px;
        font-weight: 100;
        animation: mainMenuLine 0.2s ease-out 1;
      }
    }
  }

  @keyframes mainMenuEffect {
    0% {
      text-shadow:
          0 0 0 #0c2ffb,
          0 0 0 #2cfcfd,
          0 0 0 #fb203b,
          0 0 0 #fefc4b;
    }

    25% {
      text-shadow:
          0 0.082em 0 #0c2ffb,
          0 0.092em 0 #2cfcfd,
          0 -0.022em 0 #fb203b,
          0 -0.044em 0 #fefc4b;
    }

    50% {
      text-shadow:
          0 -0.022em 0 #0c2ffb,
          0 -0.044em 0 #2cfcfd,
          0 0.062em 0 #fb203b,
          0 0.072em 0 #fefc4b;
    }

   75% {
      text-shadow:
          0 0.031em 0 #0c2ffb,
          0 0.041em 0 #2cfcfd,
          0 -0.031em 0 #fb203b,
          0 -0.041em 0 #fefc4b;
    }

    100% {
      text-shadow:
          0 0 0 #0c2ffb,
          0 0 0 #2cfcfd,
          0 0 0 #fb203b,
          0 0 0 #fefc4b;
    }
  }
}

@keyframes mainMenuLine {
  0% {opacity:0.5; left: -60px;}
  100% {opacity: 1; left: -30px;}
}

//

.separater {
  border-left: 1px solid rgba($white, 0.1);
}

.menu-contact {
  height: 100%;
}

@include media-breakpoint-down(sm) {
  .main-menu {
    .nav-link {
      font-size: 2rem;
      margin: 0.35rem 0;
    }
  }
}

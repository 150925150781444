.swiper {
  width: 100%;
  height: auto;
  padding-top: 8rem;

  .swiper-slide {
    width: 588px!important;

    &.swiper-slide-small {
      //width: 520px!important;
      text-align: center;
      overflow-x: hidden;

      .swiper-text {
        text-align: left;
        padding-right: 8rem;
      }

    }
    img {
      width: 100%;
      text-align: center!important;
      border-radius: 12px;
    }

    .swiper-text {
      margin-top: 2rem;
      padding-right: 10rem;
    }
  }

  &.dark {
    .swiper-slide {
      .swiper-text {
        color: #fff;

        h4 {
          color: #fff;
        }
      }
    }
  }

}


// Breakpoints
@include media-breakpoint-down(sm) {
  .swiper {
    .swiper-slide {
      width: 350px!important;

      .swiper-text {
        padding-right: 0rem;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .swiper {
    .swiper-slide {
      width: 500px!important;

      .swiper-text {
        padding-right: 3rem;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .swiper {
    .swiper-slide {
      width: 588px!important;

      .swiper-text {
        padding-right: 10rem;
      }
    }
  }
}

@include media-breakpoint-up(xxl) {
  .swiper {
    .swiper-slide {
      width: 588px!important;

      .swiper-text {
        padding-right: 10rem;
      }
    }
  }
}

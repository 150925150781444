body {
  font-family: $theme-font-family-primary;
  font-size: ($font-size-base * 1.125);
  font-weight: 300;
  color: $black;
  //overflow: hidden;
  //background-color: #000;
}

.portfolio-bg {
  background-color: #f9f9f9;
}

// Logo
.ak-logo {
  a {
    font-size: 18px;
    font-weight: 700;
    color: $white;
    text-decoration: none;
    img {
      width: 50px;
    }
    span {
      display: block;
      margin-top: 4px;
      &:after {
        animation-name: ak-logo-cursor;
        animation-duration: 1s;
        animation-iteration-count: infinite;
        content: "_";
      }
    }
    &:hover {
      color: $white;
    }
  }
}

@keyframes ak-logo-cursor {
  0%      {opacity: 0;}
  40%     {opacity: 0;}
  50%     {opacity: 1;}
  90%     {opacity: 1;}
  100%    {opacity: 0;}
}

//
// Theme buttons

.btn-theme {
  font-weight: 700;
  letter-spacing: 2px;
  border-radius: 200px;
  text-transform: uppercase;

  &:focus {
    margin-top: 1px;
  }
}

.btn-theme-sm {
  font-size: 12px;
  padding: 15px 30px;
}

.btn-theme-purple {
  background-color: $mk-blue;
  color: $white;
  box-shadow: 0px 28px 32px -22px rgba(10,3,235,1);

  &:hover {
    background-color: #4742F0;
    color: $white;
  }

  &:focus {
    background-color: #4742F0;
    margin-top: 1px;
    box-shadow: 0px 18px 22px -11px rgba(10,3,235,.45);
  }
}

.btn-resume {
  margin-left: 10px;
}

.btn-outline-light {
  color: #fff;
  border-color: #fff;
  &:hover {
    color: #000;
    background-color: #fff;
    border-color: #fff;
  }
}

// .outline {
//   background-color: $white;
//   border: 2px solid $mk-blue;
//   color: $mk-blue;
//   box-shadow: none;
// }

//
////

.mouse-indicator {
  margin-top: 50px;
  svg {
    height: 5%;
    width: 5%;
  }
  .mouse-wheel {
    animation-name: mouse-wheel-animation;
    animation-duration: 700ms;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

    &:hover {
    }
  }

  @keyframes mouse-wheel-animation {
    0%    {transform: translateY(0px)}
    50%   {transform: translateY(5px)}
    100%  {transform: translateY(0px)}
  }
}

// Blog

.post {
  border-bottom: 1px solid #eee;
  padding: 60px 0;
  //font-family: 'Nanum Myeongjo', serif;

  a {
    font-weight: 500;
    position: relative;
    &:hover {
      content: "";
      &:before {
        content: "/";
        position: absolute;
        left: -1rem;
      }
    }
  }

  h1 {
    a {
      color: #000;
      &:hover {
        text-decoration: none;
        content: "";
        &:before {
          content: "";
        }
      }
    }
  }
}

.blog-header {
  background-color: #395fd2;
  color: #fff;
  height: 400px;
  text-align: center;
  margin-top: 150px;

  h1 {
    color: #fff;
  }
}

.stamp {
  .author-avatar {
    margin-bottom: 30px;

    .avatar {
      margin-bottom: 0px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 15px;
    }
  }
  p {
    font-size: 15px;
    margin: 0;
  }
}

a {
  text-decoration: none;
  color: #000;
  &:hover {
    color: #000;
  }
}

.container {
  padding-right: 2rem;
  padding-left: 2rem;
}

.ak-header {
  width: 100%;
  height: 100vh;
  color: #fff;
  background-size: cover;
  position: relative;

  .container, .row {
    height: 100%;
    position: relative;
  }
}

.ak-manu-container {
  position: absolute;
  top: 2.5rem;
  z-index: 999;
  width: 100%;
}

.header-portfolio {
  background-color: $black;
  height: 66px;
  position: relative;
  z-index: 998;

  .ak-manu-container {
    top: 12px;

    .ak-logo {
      a {
        img {
          width: 40px;
        }
        span {
          display: none
        }
      }
    }
  }
  &.expanded {
    height: 100vh;
  }

  .menu-toggle {
    top: -18px;
  }
  .ak-menu-top {
    margin-top: 5px;
  }
}

.ak-menu-top {
  &.nav {
    .nav-link {
      color: #fff;
      text-transform: uppercase;
      font-family: $theme-font-family-heading-medium;
      font-size: $font-size-base * fontSize(75);
      //font-weight: 500;
      letter-spacing: 3px;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  }
}

.header-home {
  background-image: url('../images/header-bg-1.jpg');
}

.header-about {
  background-image: url('../images/header-bg-2.pg');
}

.header-work {
  background-image: url('../images/header-bg-3.jpg');
}

@include media-breakpoint-down(sm) {
  .ak-manu-container {
    top: 2rem;
    .menu-toggle {
      right: 30px;
    }
  }
}

.carousel {
  margin-top: 7rem;

  .carousel-cell {
    width: 34%;
    height: 615px;
    margin-right: 40px;
    overflow: hidden;

    /* cell number */
    // &::before {
    //   display: block;
    //   text-align: center;
    //   content: counter(carousel-cell);
    //   line-height: 200px;
    //   font-size: 80px;
    //   color: white;
    // }

    .carousel-image {
      img {
        width: 100%;
        display: block;
      }
    }

    .carousel-text {
      margin-top: 2rem;
      padding-right: 14rem;
    }

  }

  &.dark {
    .carousel-cell {
      .carousel-text {
        color: #fff;

        h4 {
          color: #fff;
        }
      }
    }
  }
}

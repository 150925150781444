// portfolio

.portfolio {
  margin-top: 2rem;
  display: block;
  h4 {
    margin-top: 1rem;
    margin-bottom: 0;
  }
  p {
    margin: 0
  }
  img {
    border-radius: 12px;
    width: 100%;
  }
}

.case-study {
  img {
    border-radius: 12px;
  }
}

.portfolio-pg {
  margin: 7rem 0 0;
  box-shadow: 0 1px 6px rgba($black, 0.16);
  background-color: $white;
  border-radius: 14px;
  overflow: hidden;
  img {
    width: 100%;
  }
}

.portfolio-mt {
  margin-top: 4rem;
}

@include media-breakpoint-down(sm) {
  .portfolio-pg {
    margin: 4rem 0 0;
  }
}
